/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import Container from '@mui/material/Container';

import { Alert, Autocomplete, Button, CircularProgress, Collapse, Divider, Grid, InputBase, Paper, TextField, useMediaQuery, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsResponse, fetchAnalytics, selectedVehicle, selectVehiclesList, setEndDateRedux, setSelectedVehicle, setStartDateRedux } from '../../features/vehicles/vehiclesSlice';
import { startDate as startDateRedux, endDate as endDateRedux } from '../../features/vehicles/vehiclesSlice';


const today = () => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let today = `${year}-${month}-${day}`;
    return today;
}
const yesterday = () => {
    let date = new Date();
    date.setDate(date.getDate() - 1)

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let today = `${year}-${month}-${day}`;
    return today;
}

const formatDate = (date) => {
    return `${date.$y}-${date.$M + 1}-${date.$D} ${date.$H}:${date.$m}:${date.$s}`
}


const SearchModule = () => {

    const vehicleList = useSelector(selectVehiclesList);
    const analytics = useSelector(analyticsResponse);

    const [startDate, setStartDate] = useState(dayjs(yesterday()));
    const [endDate, setEndDate] = useState(dayjs(today()));
    const [dateWarning, setDateWarning] = useState(false);
    const [openDateWarning, setOpenDateWarning] = useState(false);

    const [loading, setLoading] = useState(false);

    const [vehicleSel, setVehicleSel] = useState(null)

    const dispatch = useDispatch();
    const getAnalytics = useSelector(fetchAnalytics);

    const handleNewStardDate = (newValue) => {
        setStartDate(newValue);
        dispatch(setStartDateRedux(formatDate(newValue)));
        if (endDate < newValue) {
            setOpenDateWarning(true);
            setDateWarning(true);
        }
        else {
            setOpenDateWarning(false);
            setDateWarning(false);
        }
    }
    const handleNewEndDate = (newValue) => {
        setEndDate(newValue);
        dispatch(setEndDateRedux(formatDate(endDate)));
        if (newValue < startDate) {
            setOpenDateWarning(true);
            setDateWarning(true);
        }
        else {
            setOpenDateWarning(false);
            setDateWarning(false);
        }
    }
    const handleSelectedVehicle = (newValue) => {
        console.log(newValue);
        setVehicleSel(newValue);
        if (newValue === null) {
            dispatch(setSelectedVehicle(""))
        }
        else {
            dispatch(setSelectedVehicle(newValue.id))
        }
    }

    const handleSearch = () => {
        setLoading(true);
        getAnalytics(dispatch);
    }

    useEffect(() => {
        dispatch(setStartDateRedux(formatDate(startDate)));
        dispatch(setEndDateRedux(formatDate(endDate)));
    }, [])

    useEffect(() => {
        console.log(analytics);
        if (loading == true) {
            setLoading(false);
        }
    }, [analytics])



    return (
        <>
            <Box sx={{
                width: "fit-content",
                maxWidth: "620px",
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingY: "0px",
                borderRadius: "12px",
                bgcolor: "transparent",
                border: "2px solid",
                borderColor: "primary.main",
                height: "fit-content"
            }}>
                <Box sx={{ width: "100%", bgcolor: "white", borderRadius: "12px", padding: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                    <Autocomplete
                        //disablePortal
                        size="small"
                        id="combo-box-demo"
                        value={vehicleSel}
                        onChange={(event, newValue) => {
                            handleSelectedVehicle(newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        options={vehicleList?.map((option) => { return ({ id: option.id, label: option.name, disabled: option.attributes["processing.copyAttributes"] ? option.attributes["processing.copyAttributes"].includes("fuel") ? false : true : true }) })}
                        getOptionDisabled={(option) => option.disabled}
                        sx={{ flex: 1, minWidth: 160 }}
                        renderInput={(params) => <TextField size="small" label="Vehicle" {...params} error={vehicleSel == null} sx={{ padding: 0 }} />}
                    />


                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            inputFormat="YYYY/MM/DD HH:mm"
                            ampm={false}
                            label="Start Date"
                            renderInput={(params) => <TextField size="small" {...params} error={dateWarning} sx={{ maxWidth: 195 }} />}
                            value={startDate}
                            onChange={(newValue) => {
                                handleNewStardDate(newValue);
                            }}
                        />
                    </LocalizationProvider>


                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="End Date"
                            inputFormat="YYYY/MM/DD HH:mm"
                            ampm={false}
                            renderInput={(params) => <TextField size="small" {...params} error={dateWarning} sx={{ maxWidth: 195 }} />}
                            value={endDate}
                            onChange={(newValue) => {
                                handleNewEndDate(newValue)
                            }}
                        />
                    </LocalizationProvider>

                    <Box sx={{ position: 'relative' }}>
                        <IconButton disabled={loading} onClick={(e) => { handleSearch() }} color="primary" aria-label="upload picture" component="label">
                            <SearchIcon />
                        </IconButton>
                        {loading && (
                            <CircularProgress
                                size={30}
                                sx={{
                                    color: "primary.main",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-15px',
                                    marginLeft: '-15px',
                                }}
                            />
                        )}
                    </Box>


                </Box>
            </Box>
            <Box sx={{ position: "fixed", bottom: 0, left: 0, width: "100%", padding: { xs: 0, sm: 2 }, maxWidth: "450px" }}>
                <Collapse in={openDateWarning}>
                    <Alert severity="error" onClose={() => { setOpenDateWarning(false) }}>End Date must be greater than start Date</Alert>
                </Collapse>
            </Box>
        </>
    );
};
export default SearchModule;
