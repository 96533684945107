import { createTheme } from "@mui/material/styles";

const primary = {
    main: '#00B1FE',
    contrastText: '#ffffff',
}

const secondary = {
    main: '#536173',
}


export const appTheme = createTheme({
    palette: {
        type: 'light',
        primary: primary,
        secondary: secondary,
    },
    typography: {
        fontFamily: [
            'Product Sans'
        ].join(','),
    },
    components: {
        MuiMobileDatePicker: {
            styleOverrides: {
                root: {
                }
            },
            defaultProps: {
                openTo: "year",
                views: ["year", "month", 'day'],
                inputFormat: "MM/DD/YYYY"
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: primary.main + " !important",
                    },
                    "&.Mui-selected:hover": {
                        backgroundColor: primary.main + " !important",

                    }
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: primary.light,
                    "& .MuiTableCell-root": {
                        fontWeight: "bold"
                    }
                }
            }
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {

                }
            }
        }
    }
    

});